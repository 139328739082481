import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Breadcrumb from "../../components/breadcrumb"
import HorairesHeader from "../../components/horairesHeader"

import next from "../../images/icons/next.svg"

const CoursTheoriquesPage = ({ data }) => (
  <Layout>
    <Seo title="Cours théoriques" breadcrumb={[
      { url: "les-horaires", name: "Les horaires"},
      { url: "cours-theoriques", name: "Cours théoriques"},
    ]} />
    <Breadcrumb paths={[
      { url: "/les-horaires/", name: "Les horaires" },
      { url: null, name: "Cours théoriques" }
    ]} />

    <HorairesHeader active={2} />

    <ul>
      {data.allStrapiCoursTheoriques.edges.map(document => (
        <li key={document.node.id} className="mb-8">
          <Link
            to={`/les-horaires/cours-theoriques/${document.node.slug}`}
            className="block md:w-1/2 group -m-2 p-2 relative rounded-md hover:bg-white hover:bg-opacity-10"
          >
            <img
              src={next}
              width={20}
              className="float-right opacity-0 group-hover:opacity-100 mt-4"
              alt=""
            />
            <h3 className="text-primary mt-0">{document.node.title}</h3>
            <p className="text-sm opacity-60">
              Dernière mise à jour : {new Date(document.node.updated_at).toLocaleString('fr-FR', { weekday: "long", year: "numeric", month: "long", day: "numeric" })}
            </p>
          </Link>
        </li>
      ))}
    </ul>
  </Layout>
)

export default CoursTheoriquesPage

export const pageQuery = graphql`
  query CoursTheoriquesQuery {
    allStrapiCoursTheoriques(sort: {fields: created_at, order: DESC}) {
      edges {
        node {
          id
          slug
          title
          updated_at
        }
      }
    }
  }
`
